<template>
    <div class="metal-shelves">
         <generic-bg-color></generic-bg-color>
        <!-- Options -->
        <boxes-services
            :full_top="true"
            :data_box="data_box">
        </boxes-services>
        <!-- Pesada -->
        <gallery-generic :gallery_data="gallery_heavy">
        </gallery-generic>
        <!-- Mediana -->
        <!-- <gallery-generic :gallery_data="gallery_medium">
        </gallery-generic> -->
        <!-- Light -->
        <!-- <gallery-generic :gallery_data="gallery_light">
        </gallery-generic> -->
        <!-- Manual -->
        <gallery-generic :gallery_data="gallery_manual">
        </gallery-generic>
        <!-- Entrepisos -->
        <gallery-generic :gallery_data="gallery_mezzanine">
        </gallery-generic>
        <!-- Entrepaños -->
        <!-- <gallery-generic :gallery_data="gallery_mesh">
        </gallery-generic> -->
        <!-- FOOTER -->
        <!-- <div class="site-building-wrapper">
            <img src="@/assets/images/build.jpg" alt="Página en construcción">
            <br><br>
            <h2>Página en construcción</h2>
            <br>
            <router-link tag="button" class="contact-button" to="/">
                Ir al inicio
            </router-link>
        </div> -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Estantería Metálica',
		meta: [
			// Ummami Express
			{ name: 'description', content:'fabrica de estanteria metalicas, estanteria metalica pesada, estanteria metalica liviana,sistemas de almacenamiento.' },
			{ name: 'keywords', content:'estanteria metalica, estanterias metalicas, estanteria metalica bucaramanga, estanteria metalica barranquilla, estanteria pesada, estanteria liviana, fabrica de estanterias metalicas, racks de almacenamiento, estanterias metalicas en bucaramanga, estanterias metalicas en barranquilla' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Estantería Metálica'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'fabrica de estanteria metalicas, estanteria metalica pesada, estanteria metalica liviana,sistemas de almacenamiento.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Estantería Metálica'},
			{name: 'twitter:description', content: 'fabrica de estanteria metalicas, estanteria metalica pesada, estanteria metalica liviana,sistemas de almacenamiento.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Estantería Metálica'},
			{itemprop: 'description', content: 'fabrica de estanteria metalicas, estanteria metalica pesada, estanteria metalica liviana,sistemas de almacenamiento.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
           data_box: {
                title: 'Estanterías Metálicas',
                description: `
                       Tenemos más de 35 años ayudando a a las empresas del país a mejorar su productividad 
                       con sistemas de almacenamiento eficientes.
                `,
                box_1: {
                    title: 'Pesada',
                    url_image: 'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_1.jpg',
                    name_link: 'heavy-shelves'
                },
                box_2: {
                    title: 'Carga Manual',
                    url_image: 'estanterias-metalicas/rack-picking/ranurada/ranurada_1.jpg',
                    name_link: 'rack-picking'
                },
                box_3: {
                    title: 'Entrepisos',
                    url_image: 'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/entrepisos_1.jpg',
                    name_link: 'mezzanines'
                }
            },
            gallery_heavy: {
                title: 'Estantería Pesada',
                description: `
                    <b><i>Entorno productivo de seguridad y orden a sus productos en el área de almacenamiento.</i></b><br>
                    Es un sistema funcional para acoplar, cuidar y distribuir materiales pesados de
                    difícil manejo de una empresa o industria. Son especiales para carga pesada de
                    diferente tipo y se puede implementar a cualquier empresa según la necesidad de
                    cada una y el tipo de distribución.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'heavy-shelves',
                slides: [
                    'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_1.jpg',
                    'estanterias-metalicas/pesada/productos/drive-in/drive_in_1.jpg',
                ]
            },
            gallery_medium: {
                title: 'Estantería Mediana',
                description: `
                    <b><i>Capacidad de carga versátil que resuelva necesidades de almacenamiento.</i></b><br>
                    Este sistema es totalmente ligero y ensamblable. Cada entrepaño soporta una
                    carga de hasta 260 Kg. Se acoplan uniformemente y se distribuyen ofreciendo la
                    posibilidad de aumentar su estructura modularmente.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'medium-shelves',
                slides: [
                    'estanterias-metalicas/mediana/mediana.jpg',
                    'estanterias-metalicas/mediana/mini-rack-2.jpg',
                    'estanterias-metalicas/mediana/trial_1.jpg',
                    'estanterias-metalicas/mediana/mini-rack-1.jpg',
                    'estanterias-metalicas/mediana/trial_2.jpg'
                ]
            },
            gallery_light: {
                title: 'Estantería Liviana',
                description: `
                    <b><i>Estructura ajustable y de perfecta estabilidad con facilidad y rapidez de montaje.</i></b><br>
                    Es un sistema de almacenaje ligero que proporciona la mejor solución a las
                    diversas necesidades de almacenaje de cargas medias y ligeras, siempre que
                    éstas se manipulen de manera manual. Cuenta con adaptabilidad a distintas
                    medidas de paneles, así como la variedad de elementos que facilitan acoplarse a
                    espacios disponibles.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'light-shelves',
                slides: [
                    'estanterias-metalicas/liviana/liviana_1.jpg',
                    'estanterias-metalicas/liviana/liviana_2.jpg',
                    'estanterias-metalicas/liviana/liviana_3.jpg',
                    'estanterias-metalicas/liviana/liviana_4.jpg'
                ]
            },
            gallery_manual: {
                title: 'Estantería de Carga Manual',
                description: `
                    Diseñadas para la carga y descarga manual de productos sueltos.  las racks para picking pueden adoptar el sistema convencional y son personalizables 
                    con accesorios en caso de que se necesite manejar productos con formatos especiales como neumáticos o recambios.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'rack-picking',
                slides: [
                    'estanterias-metalicas/rack-picking/ranurada/ranurada_1.jpg',
                    'estanterias-metalicas/rack-picking/liviana/liviana_1.jpg',
                ]
            },
            gallery_mezzanine: {
                title: 'Mezzanines - Entrepisos',
                description: `
                    <b><i>Aprovechamiento al máximo de la altura. De fácil y rápida instalación y desmontaje.</i></b><br>
                    El entrepiso son niveles de pisos sostenidos por la misma estructura de mezanine
                    utilizando el mismo, con un número de niveles para carga manual. Se aprovecha
                    al máximo la altura del almacén y se manejan los productos de forma práctica sin
                    necesidad de montacargas. Idóneo para cargas ligeras o semipesadas.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'mezzanines',
                slides: [
                    'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/entrepisos_1.jpg',
                    'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/entrepisos_2.jpg',
                    'estanterias-metalicas/entrepisos/productos/mezzanines/mezzanine_1.jpg'
                ]                
            },
            gallery_mesh: {
                title: 'Entrepaños de Malla',
                description: `
                    <b><i>Fáciles instalar, amigables con el producto, seguros, ecológicos y seguros.</i></b><br>
                    Son fuertes de 1000 kg se pueden cargar a mano o con monta cargas. Este
                    sistema consta de mallas electro soldadas con refuerzos metálicos y acabado con
                    pintura epoxi poliéster electrostática.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'mezzanines',
                slides: [
                    'estanterias-metalicas/entrepanos-de-malla/malla_1.jpg',
                    'estanterias-metalicas/entrepanos-de-malla/malla_2.jpg',
                    'estanterias-metalicas/entrepanos-de-malla/malla_3.jpg'
                ]                  
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/_variables.scss';
    @import '@/assets/css/_mixins.scss';

    .site-building-wrapper {
        padding: 20px 10px;
        text-align: center;

        img {
            width: 100%;
            max-width: 1100px;
            margin: 0px auto;
        }

        button {
            @extend %generic-pico-button;
        }
    }
</style>